<template>
  <div>
    <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
      <NotiBar v-if="msgShow" :type="msgType" :msg="msgText" />

      <form @submit.prevent="saveForm">
        <fieldset class="form-block">
          <div class="form-container">
            <SmallTitle title="การเสนอราคา" />
            <div class="input">
              <Label text="จำนวนผู้เข้าเสนอราคาสูงสุดต่อโครงการ" :required="true" />
              <select v-model="setting.default_auction_limit">
                <option v-for="d in 10" :key="d">{{ d }}</option>
              </select>
            </div>
            <div class="input">
              <Label text="จำนวนวันที่เปิดให้เสนอราคา" :required="true" />
              <select v-model="setting.default_auction_day">
                <option v-for="d in 30" :key="d">{{ d }}</option>
              </select>
              <div class="input-description">จะนับจากวันที่ผู้ดูแลระบบ Approve โครงการ</div>
            </div>

            <!-- <SmallTitle title="การส่งข้อความ" class="mt-20" />
            <div class="input row2">
              <div class="col">
                <Label text="Hour" :required="true" />
                <select v-model="setting.broadcast_hour">
                  <option v-for="h in 23" :key="h">{{ h | zeroBefore }}</option>
                </select>
              </div>
              <div class="col">
                <Label text="Minute" :required="true" />
                <select v-model="setting.broadcast_minute">
                  <option value="0">00</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div> -->
          </div>
        </fieldset>
          
        <!-- <fieldset class="form-block">
          <div class="form-container">
            <div class="input">
              <Label text="Noti แจ้ง Admin เมื่อผู้ใช้งานสร้างโครงการใหม่เพื่อยืนยัน" :required="true" />
              <textarea v-model="setting.noti_admin_new_project" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Broadcase เมื่อ Admin ยืนยันโครงการใหม่" :required="true" />
              <textarea v-model="setting.broadcast_text_newproject" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้งเจ้าของโครงการเมื่อ Admin ยืนยันโครงการสร้างใหม่" :required="true" />
              <textarea v-model="setting.noti_member_project_approved" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้ง Admin เมื่อมีการเสนอราคาใหม่เพื่อยืนยัน" :required="true" />
              <textarea v-model="setting.noti_admin_new_auctioneer" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้งเจ้าของโครงการว่ามีผู้เสนอราคาใหม่" :required="true" />
              <textarea v-model="setting.noti_member_new_auctioneer" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้ง Admin เมื่อเจ้าของโครงการเลือกผู้ชนะเพื่อยืนยัน" :required="true" />
              <textarea v-model="setting.noti_admin_choose_winner" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้งเจ้าของโครงการเมื่อ Admin ยืนยันการเสนอราคาเสร็จสมบูรณ์" :required="true" />
              <textarea v-model="setting.noti_member_winner_approved" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้งผู้ชนะการเสนอราคา" :required="true" />
              <textarea v-model="setting.noti_member_auction_winner" rows="8"></textarea>
            </div>
            
            <div class="input">
              <Label text="Noti แจ้งผู้เสนอราคาทุกรายเมื่อการเสนอราคาเสร็จสมบูรณ์" :required="true" />
              <textarea v-model="setting.noti_member_auction_lost" rows="8"></textarea>
            </div>
          </div>
          
        </fieldset> -->

        <div class="button-block">
          <button type="submit" class="btn btn-update">
            <i class="fa fa-save fa-lg"></i> Save
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
// // @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import NotiBar from '@/components/common/NotiBar.vue';
import SmallTitle from '@/components/common/SmallTitle.vue';
import Label from '@/components/common/form/Label.vue';

export default {
  components: {
    Label,
    NotiBar,
    SmallTitle,
  },
  data() {
    return {
      loaded: false,
      msgShow: false,
      msgType:'success',
      msgText:'',
      setting: {
        broadcast_hour:   '12',
        broadcast_minute:  '0',
        default_auction_day: 3,
      }
    }
  },
  filters: {
    zeroBefore(num) {
      return num<10 ? '0'+num : num;
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Setting');
    this.$store.commit('setHeadTitle', 'Setting');
    this.setForm();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    setForm:function() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get('setting')
        .then( (res) => {
          this.setting = res.data.result; console.log(this.setting);
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function(e) {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .post('/setting', this.setting)
        .then( () => { 
          this.setForm();
          this.msgShow = true;
          this.msgText = 'Save data complete';
        });
      e.preventDefault();
    }
  }
}
</script>


<style scoped>
.tb-data .col-name {width: 25%;}
.tb-data .col-user {width: auto;}
.tb-data .col-email {width: 25%;}
.tb-data .col-role {width: 15%;}
.tb-data .col-date {width: 15%;}
</style>